import Swiper from '../vendor/swiper';

let goalsSlider;

const goalsSliderInit = () => {
  goalsSlider = new Swiper('.goals-slider', {
    direction: 'vertical',
    speed: 400,
    mousewheel: true,
    simulateTouch: false,
    wrapperClass: 'goals-slider__slides-wrap',
    slideClass: 'goals-slider__slide',
    slideActiveClass: 'goals-slider__slide--active',
    navigation: {
      nextEl: '.scroll-icon',
    },
    pagination: {
      el: '.goals-slider__pagination',
      type: 'bullets',
      bulletClass: 'goals-slider__bullet',
      bulletActiveClass: 'goals-slider__bullet--active',
      modifierClass: '',
      clickable: true,
    },
  });
};

const goalsSliderRemove = () => {
  goalsSlider.destroy();
};

export {goalsSliderInit, goalsSliderRemove};

const initWidth = (elementClass) => {
  let headerElement = document.querySelector('.' + elementClass);

  if (headerElement) {
    const calcWidth = () => {
      let computedStyle = getComputedStyle(document.querySelector('.header__wrapper'), null);
      let headerWidth = parseInt(computedStyle.getPropertyValue('width'), 10);
      let headerPaddings = parseInt(computedStyle.getPropertyValue('padding-left'), 10) + parseInt(computedStyle.getPropertyValue('padding-right'), 10);
      let leftNav = document.querySelector('.header__nav--back');
      let rightNav = document.querySelector('.main-nav');

      if (leftNav && rightNav) {
        let leftNavWidth = leftNav.offsetWidth;
        let rightNavWidth = rightNav.offsetWidth;
        let biggestWidth = Math.max(leftNavWidth, rightNavWidth);
        let elWidth = headerWidth - (biggestWidth * 2 + headerPaddings);
        headerElement.style.setProperty('--elWidth', elWidth + 'px');
      }
    };

    calcWidth();

    window.addEventListener('resize', calcWidth);
  }
};

const headerElWidth = () => {
  initWidth('inner-nav');
};

export {headerElWidth};

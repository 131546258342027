/* eslint-disable */
import {audioFormat, playAudio, stopAudio} from "./audio";
import barba from '@barba/core';

let cards = '';
let showAllBtn = '';
let activeCards;
let switcher;
window.mainBlock = false;
const vh100 = window.innerHeight + 4;

if (document.querySelector('.card')) {
  showAllBtn = document.querySelector('.js-show-all');
}

let allClicks = (evt) => {
  const target = evt.target;
  const nextCard = target.closest('.cards__next-js');
  const prevCard = target.closest('.cards__prev-js');
  const cardElement = target.closest('.card');
  const showAllBtn = target.closest('.js-show-all');
  const switchTouchBtn = target.closest('.change-card-input');
  const resultBtn = target.closest('.sculpture-result__btn');
  window.mainBlock = document.querySelector('.xxx-page') || false;

  if (nextCard && cardElement) {
    evt.preventDefault();
    removeCard(cardElement, window.mainBlock);
  }

  if (prevCard && cardElement) {
    evt.preventDefault();
    let id = cardElement.dataset.prevCard;
    let card = document.querySelector(`.card--${id}`);

    resetCard(card, true);
  }

  if (showAllBtn) {
    const cards = document.querySelectorAll('.card:not(.card--remove-animation)');
    cards.forEach((el) => {
      removeCard(el, mainBlock);
    });

    finishAnimation(mainBlock);
  }

  if (switchTouchBtn) {
    let card = document.querySelector('.card:not(.card--remove-animation)')

    if(card.querySelector('input').checked) {
      card.querySelector('input').checked = false;
      card.classList.remove('card--active');
    } else {
      card.querySelector('input').checked = true;
      card.classList.add('card--active');
    }
  }


  let counterNxt = true;
  if (evt.target.id === 'nextBtn' || target.closest('.scroll-icon') || target.closest('.cards-nav--disc')) {
    evt.preventDefault();

    if (counterNxt) {
      scrollToNextSection(mainBlock);
      mainBlock.classList.add('disabled-btn')
      counterNxt = false;

      setTimeout(() => {
        mainBlock.classList.remove('disabled-btn')
        counterNxt = true;
      }, 800)
    }
  }

  let counterPrv = true;
  if (evt.target.id === 'prevBtn') {
    evt.preventDefault();

    if (counterPrv) {
      scrollToPrevSection(mainBlock);
      mainBlock.classList.add('disabled-btn')
      counterPrv = false;

      setTimeout(() => {
        mainBlock.classList.remove('disabled-btn')
        counterPrv = true;
      }, 800)
    }
  }

  if (resultBtn) {
    window.mainBlock.classList.remove('active--result-text')
    window.mainBlock.classList.add('active--result-slider')
  }

  if (evt.target.closest('.header__toggle')) {
    if (window.matchMedia('(max-width: 767px)').matches && !mainBlock.classList.contains('active--intro')) {
      evt.preventDefault();

      if (counterPrv) {
        scrollToPrevSection(mainBlock);
        mainBlock.classList.add('disabled-btn')
        counterPrv = false;

        setTimeout(() => {
          mainBlock.classList.remove('disabled-btn')
          counterPrv = true;
        }, 800)
      }

    } else {
      barba.go(`catalog-slider.html`);
    }
  }
}

document.addEventListener('change', (evt) => {
  const target = evt.target;
  const card = target.closest('.card');
  const switchButton = target.closest('.switch-button');

  if (card && switchButton) {
    if(!card.querySelector('input').checked) {
      card.querySelector('input').checked = false;
      card.classList.remove('card--active');
    } else {
      card.querySelector('input').checked = true;
      card.classList.add('card--active');
    }
  }

});

const scrollToNextSection = (mainBlock) => {
  let allSections = document.querySelectorAll('.scrollToMe').length;
  window.matchMedia('(min-width: 768px)').matches ? allSections-- : allSections;

  if (window.current >= allSections) {

    if(!document.querySelector('.cards-history--active')) {
      mainBlock.classList.add('cards-history--active');
    }
    nextCardMobile(mainBlock);
    window.current = allSections;
  } else {
    window.current++
  }

  mainBlock.scrollTo({top: window.current * vh100, behavior: "smooth"});
}

const scrollToPrevSection = (mainBlock) => {
  activeCards = document.querySelectorAll('.card--remove-animation').length;

  if (activeCards > 0) {
    window.cardNumber--;
    prevCardMobile();
  } else {
    if(document.querySelector('.cards-history--active')) {
      mainBlock.classList.remove('cards-history--active');
    }

    window.current <= 0 ? window.current = 0 : window.current--;
  }

  mainBlock.scrollTo({top: window.current * vh100, behavior: "smooth"});
}


let cardItem;
const nextCardMobile = (mainBlock) => {
  cardItem = document.querySelector('.card:not(.card--remove-animation)');
  cards = document.querySelectorAll('.card').length;

  if (cardItem && window.cardNumber >= cards) {
    window.cardNumber = cards;
    finishAnimation(mainBlock);
  } else {
    window.cardNumber++;
    removeCard(cardItem, mainBlock)
  }
}
const prevCardMobile = () => {
  let cards = document.querySelectorAll('.card--remove-animation');
  let cardsNumber = cards.length - 1;

  if(cards[cardsNumber]) {
    resetCard(cards[cardsNumber])
  }
}

const resetCard = (card) => {
  setTimeout(() => {
    card.classList.remove('card--remove-animation');
  }, 10);

  card.classList.remove('card--active');
  card.style.display = '';
  card.style.visibility = '';
  card.querySelector('input').checked = false;

  let classCard = card.querySelector('.cards__next-js').dataset.mainClass;
  if (classCard) {
    window.mainBlock.classList.remove(classCard)
  }

  card.querySelector('input').checked = false;
};
const removeCard = (cardElement) => {
  if(!cardElement) return;
  cardElement.classList.add('card--remove-animation');
  let mainClass = cardElement.querySelector('.cards__next-js').dataset.mainClass;

  setTimeout(() => {
    cardElement.style.display = 'none';
    mainClass
      ? window.mainBlock.classList.add(mainClass)
      : finishAnimation(mainBlock);

  }, 400);

  showAllBtn = document.querySelector('.js-show-all');

  if (showAllBtn.classList.contains('button--disabled')) {
    showAllBtn.classList.remove('button--disabled');
  }
};

const finishAnimation = (mainBlock) => {
  const audioName = mainBlock.dataset.audioName;
  mainBlock.classList.add('finish-animation');
  mainBlock.classList.remove('cards-history--active');

  stopAudio();

  setTimeout(() => {
    mainBlock.classList.add('remove-animation');
  }, 400);

  setTimeout(() => {
    if (audioName !== 'psihea') {
      playAudio(`audio/${audioName}_4.${audioFormat}`);
    }
  }, 2400);

  setTimeout(() => {
    mainBlock.classList.add('active--result');
    window.resultScreen = true;

    if (window.matchMedia('(max-width: 767px)').matches) {
      mainBlock.classList.add('active--result-text');
      document.body.style.overflow = '';
    }
  }, 3000);
};

export {allClicks};

import {disableScrolling, enableScrolling} from '../utils/scroll-lock';

let body;
let menu;
let menuIsActive;
let menuTarget;
let closeBtnTarget;
let hamburgerBtnTarget;

const burgerMenu = () => {
  const toggleMenu = () => {
    body = document.body;

    if (body.classList.contains('opened-menu')) {
      //disableScrolling();
      body.classList.remove('opened-menu--animation');
      setTimeout(() => {
        body.classList.remove('opened-menu');
      }, 300);
    } else {
      //enableScrolling();
      body.classList.add('opened-menu');
      setTimeout(() => {
        body.classList.add('opened-menu--animation');
      }, 10);
    }
  };

  document.addEventListener('click', (e) => {
    hamburgerBtnTarget = e.target.closest('.js-burger');
    closeBtnTarget = e.target.closest('.js-close');
    menu = document.querySelector('.menu');

    if ((closeBtnTarget || hamburgerBtnTarget) && menu) {
      e.stopPropagation();

      toggleMenu();
    }
  });

  document.addEventListener('click', (e) => {
    menu = document.querySelector('.menu');
    menuTarget = e.target.closest('.menu__wrapper');

    if (e.target.closest('.menu') && body.classList.contains('opened-menu')) {
      menuIsActive = body.classList.contains('opened-menu');
    } else {
      menuIsActive = '';
    }
    hamburgerBtnTarget = e.target.closest('.js-burger');

    if (!menuTarget && !hamburgerBtnTarget && menuIsActive) {
      toggleMenu();
    }
  });
};

const burgerCheck = () => {
  if (document.body.classList.contains('opened-menu')) {
    document.body.classList.remove('opened-menu--animation');
    setTimeout(() => {
      document.body.classList.remove('opened-menu');
    }, 300);
  }
};

export {burgerMenu, burgerCheck};

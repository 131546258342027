let slider;
let inputRange;
let currentValue;
let transitionDuration;
let mobileSwitch;

const setSliderProps = (value, drag = false) => {
  if (drag) {
    slider.style.setProperty('--slider-transition-duration', '0s');
  } else {
    slider.style.setProperty('--slider-transition-duration', `${transitionDuration}s`);
  }

  slider.style.setProperty('--slider-progress', `${value}%`);
  slider.style.setProperty('--slider-width', `${slider.offsetWidth}px`);

  currentValue = value;
};

const calcProps = (value) => {
  const range = Math.abs(currentValue - value);
  const isDrag = range === 1;
  transitionDuration = range / 66;
  setSliderProps(value, isDrag);
};

const switchHandler = (evt) => {
  if (evt.target.checked) {
    calcProps(98);
  } else {
    calcProps(2);
  }
};

const inputChangeHandler = (evt) => {
  const rangeValue = evt.target.value;
  calcProps(rangeValue);
};

const beforeAfterSliderInit = () => {
  slider = document.querySelector('.js-before-after-slider');
  slider.style.setProperty('--slider-width', `${slider.offsetWidth}px`);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (slider) {
    inputRange = slider.querySelector('.js-before-after-slider-range-input');
    mobileSwitch = document.querySelector('.js-before-after-slider-switch');
    currentValue = inputRange.value;

    if (inputRange && isSafari && window.innerWidth <= 1024) {
      inputRange.addEventListener('change', inputChangeHandler);
    } else if (inputRange) {
      inputRange.addEventListener('input', inputChangeHandler);
    }

    mobileSwitch.addEventListener('change', switchHandler);

    if (window.innerWidth < 768) {
      calcProps(98);
    }
  }
};

const beforeAfterSliderRemove = () => {
  slider = document.querySelector('.js-before-after-slider');

  if (slider) {
    inputRange = slider.querySelector('.js-before-after-slider-range-input');
    mobileSwitch = document.querySelector('.js-before-after-slider-switch');

    if (inputRange) {
      inputRange.removeEventListener('input', inputChangeHandler);
      inputRange.removeEventListener('change', inputChangeHandler);
    }

    mobileSwitch.removeEventListener('change', switchHandler);
  }
};

function WebpIsSupported(callback) {
  // If the browser doesn't has the method createImageBitmap, you can't display webp format
  if (!window.createImageBitmap) {
    callback(false);
    return;
  }

  // Base64 representation of a white point image
  var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

  // Retrieve the Image in Blob Format
  fetch(webpdata).then(function (response) {
    return response.blob();
  }).then(function (blob) {
    // If the createImageBitmap method succeeds, return true, otherwise false
    createImageBitmap(blob).then(function () {
      callback(true);
    }, function () {
      callback(false);
    });
  });
}

WebpIsSupported(function (isSupported) {
  if (isSupported) {
    document.body.classList.add('webp');
  }
});

export {beforeAfterSliderInit, beforeAfterSliderRemove};

import {isMobile} from "./is-mobile";

const scrollBtn = () => {
  const vh100 = window.innerHeight;
  const scrollItem = document.querySelector('.js-scroll');

  if (!scrollItem) return;

  if (!isMobile()) {
    scrollItem.addEventListener('click', () => {
      window.scrollTo({top: window.pageYOffset + vh100, behavior: 'smooth'});
    });
  }
};

export {scrollBtn};

import {Howl} from 'howler';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

let audio = false;
let ambienceSound = false;
let isSoundMuted = sessionStorage.getItem('sound') === 'on';
let audioFormat = isSafari ? 'mp3' : 'ogg';

const playAudio = (url) => {
  stopAudio();

  if (!isSoundMuted && url) {
    audio = new Howl({
      src: [url],
      autoplay: true,
    });
  }
};

const stopAudio = () => {
  if (audio) {
    audio.unload();
    audio = false;
  }
};

const toggleSound = (transition = true, mute) => {
  if (isSoundMuted && !mute) {
    document.body.classList.remove('sound-off');
  } else if (mute) {
    document.body.classList.add('sound-off');
  } else {
    document.body.classList.toggle('sound-off');
  }

  isSoundMuted = document.body.classList.contains('sound-off');
  if (audio && isSoundMuted && transition) {
    audio.fade(1.0, 0.0, 500);
    setTimeout(() => {
      stopAudio();
    }, 501);
  } else if (audio && isSoundMuted) {
    stopAudio();
  }

  if (ambienceSound && isSoundMuted && transition) {
    ambienceSound.fade(1.0, 0.0, 500);
    setTimeout(() => {
      ambienceSound.pause();
    }, 501);
  } else if (ambienceSound && isSoundMuted) {
    ambienceSound.volume(0.0);
    ambienceSound.pause();
  } else if (!isSoundMuted) {
    ambienceSound.volume(1.0);
    ambienceSound.play();
  }
};

const initSound = () => {
  ambienceSound = new Howl({
    src: [`audio/ambience.${audioFormat}`],
    preload: true,
    loop: true,
  });

  ambienceSound.play();

  toggleSound(false, true);

  document.addEventListener('click', (evt) => {
    const target = evt.target;
    const muteBtn = target.closest('.js-mute') || false;

    if (muteBtn) {
      toggleSound();
      sessionStorage.setItem('sound', document.body.classList.contains('sound-off') ? 'off' : 'on');
    }
  });
};

export {playAudio, stopAudio, initSound, toggleSound, audioFormat};

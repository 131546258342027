import Swiper from '../vendor/swiper';
import {audioFormat, playAudio, stopAudio} from './audio';

let catalogSwiper;

const catalogSliderInit = () => {
  // eslint-disable-next-line no-new, no-undef
  catalogSwiper = new Swiper('.catalog-carousel__slider', {
    direction: 'horizontal',
    loop: true,
    speed: 1000,
    loopAdditionalSlides: 1,
    wrapperClass: 'catalog-carousel__sliders-wrap',
    slideClass: 'catalog-carousel__slide',
    slideActiveClass: 'catalog-slide--active',
    slideNextClass: 'catalog-slide--next',
    slidePrevClass: 'catalog-slide--prev',
    navigation: {
      nextEl: '.catalog-carousel__button--down',
      prevEl: '.catalog-carousel__button--up',
    },
    mousewheel: {
      releaseOnEdges: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        direction: 'vertical',
        height: 380,
        allowTouchMove: false,
        longSwipes: false,
        shortSwipes: false,
      },
    },
    on: {
      slideChange: (slider) => {
        setTimeout(() => {
          const activeSlide = slider.slides[slider.activeIndex];
          const url = `${activeSlide.dataset.sound}.${audioFormat}`;
          playAudio(url);
        }, 600);
      },
    },
  });
};

const catalogSliderRemove = () => {
  catalogSwiper.destroy();
  stopAudio();
};

export {catalogSliderInit, catalogSliderRemove};

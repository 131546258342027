import Swiper from '../vendor/swiper';
import barba from '@barba/core';

let swiper;
let catalogBlock;

const scrollHandler = () => {
  let isBottom = catalogBlock.offsetHeight - (window.scrollY + window.innerHeight) <= 35;
  if (catalogBlock) {
    if (isBottom) {
      catalogBlock.classList.add('catalog--bottom');
    } else {
      catalogBlock.classList.remove('catalog--bottom');
    }
  }
}

const initCatalog = () => {
  catalogBlock = document.querySelector('.catalog');

  if (catalogBlock && window.matchMedia('(max-width: 767px)').matches) {
    document.addEventListener('scroll', scrollHandler)

  }

  if (!catalogBlock || window.matchMedia('(max-width: 767px)').matches) {
    return;
  }

  const firstImage = document.querySelector('.catalog-intro__item--first');
  const secondImage = document.querySelector('.catalog-intro__item--second');
  const imagesWrap = document.querySelector('.catalog-intro__images-wrap');
  const imagesAnimationSlides = document.querySelectorAll('[data-animate-img]');
  const sliderSlide = document.querySelector('[data-animate-slider="true"]');
  let MAX_OFFSET;

  if (window.matchMedia('(max-width: 767px)').matches) {
    MAX_OFFSET = 20;
  } else if (window.matchMedia('(max-width: 1023px)').matches) {
    MAX_OFFSET = 30;
  } else {
    MAX_OFFSET = 39;
  }

  const slideOffset = (index, maxI) => {
    return (index / maxI * MAX_OFFSET).toString() + '%';
  };

  const firstScreenBlock = document.querySelector('.catalog__slider .swiper-slide');

  // eslint-disable-next-line no-undef
  swiper = new Swiper('.catalog__slider', {
    direction: 'vertical',
    mousewheel: true,
    slidesPerView: 1,
    preventInteractionOnTransition: true,
    resistanceRatio: 10,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      320: {
        speed: 500,
        shortSwipes: true,
        longSwipesMs: 300,
        longSwipesRatio: 0.5,
      },
      1024: {
        speed: 800,
        shortSwipes: false,
        longSwipesMs: 100,
        longSwipesRatio: 1,
      },
    },
    on: {
      init: () => {
        if (firstScreenBlock) {
          const isFirstScreenPreview = firstScreenBlock.dataset.animateFirst === 'true';

          if (isFirstScreenPreview) {
            imagesWrap.classList.add('catalog-intro__images-wrap--hide');
          }
        }
      },
    },
  });

  const maxIndexImages = Math.max(...Array(...imagesAnimationSlides).map((e) => e.dataset.animateImg));

  const displaceImage = (activeIndex, maxI) => {
    if (firstImage && secondImage) {
      firstImage.style.left = slideOffset(activeIndex, maxI);
      secondImage.style.right = slideOffset(activeIndex, maxI);
      firstImage.style.transform = `translateX(-${slideOffset(activeIndex, maxI)})`;
      secondImage.style.transform = `translateX(${slideOffset(activeIndex, maxI)})`;
    }
  };

  const clearImage = () => {
    if (firstImage && secondImage) {
      firstImage.style.left = '';
      secondImage.style.right = '';
      firstImage.style.transform = '';
      secondImage.style.transform = '';
      setTimeout(() => {
        barba.go('catalog-slider.html');
      }, 1200);
    }
  };

  let sliderAnimateTimer;

  swiper.on('slideChange', () => {
    const imageIndex = swiper.slides[swiper.activeIndex].dataset.animateImg;
    displaceImage(imageIndex, maxIndexImages);
    const isSliderSlide = swiper.slides[swiper.activeIndex].dataset.animateSlider === 'true';
    const isFirstScreen = swiper.slides[swiper.activeIndex].dataset.animateFirst === 'true';


    if (window.matchMedia('(max-width: 767px)').matches) {
      if (swiper.activeIndex === 2) {
        imagesWrap.classList.add('catalog-intro__images-wrap--no-text-sm');
      } else {
        imagesWrap.classList.remove('catalog-intro__images-wrap--no-text-sm');
      }
    }
    if (window.matchMedia('(max-width: 400px)').matches) {
      if (swiper.activeIndex === 1 || swiper.activeIndex === 2) {
        imagesWrap.classList.add('catalog-intro__images-wrap--no-text-sm');
      } else {
        imagesWrap.classList.remove('catalog-intro__images-wrap--no-text-sm');
      }
    }

    if (isSliderSlide) {
      clearImage();
      if (sliderSlide) {
        sliderSlide.style.transitionDelay = '1.2s';
      }
      imagesWrap.classList.add('active');
      sliderAnimateTimer = setTimeout(() => {
        imagesWrap.style.display = 'none';
      }, 1200);
    } else {
      clearTimeout(sliderAnimateTimer);
      imagesWrap.classList.remove('active');
      imagesWrap.style.display = 'block';
      if (sliderSlide) {
        sliderSlide.style.transitionDelay = '0s';
      }
    }

    if (isFirstScreen) {
      imagesWrap.classList.add('catalog-intro__images-wrap--hide');
    } else {
      imagesWrap.classList.remove('catalog-intro__images-wrap--hide');
    }
  });
};

const removeCatalog = () => {
  document.removeEventListener('scroll', scrollHandler)

  if (!catalogBlock) {
    return;
  }

  swiper.destroy();
};

export {initCatalog, removeCatalog};

const isMobile = () => {
  if (('ontouchstart' in window)) {
    document.body.classList.add('touch');
    return true;
  } else {
    return false;
  }
};

export {isMobile};

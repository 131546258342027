const mobileCompareInit = () => {
  let compareOpenButton;
  let compareContainer;
  let mediaQuery;
  let openCompare;

  document.addEventListener('click', (e) => {
    compareOpenButton = e.target.closest('.health__scroll-icon');
    mediaQuery = window.matchMedia('(max-width: 1023px)');

    if (compareOpenButton && mediaQuery.matches) {
      e.preventDefault();

      compareContainer = document.querySelector('.sculpture-result');

      openCompare = () => {
        compareContainer.classList.add('sculpture-result--compare-block');
      };

      openCompare();
    }
  });
};

export {mobileCompareInit};

import barba from '@barba/core';
import barbaRouter from '@barba/router';
import barbaCss from '@barba/css';
import {headerElWidth} from './header-width';
import {enableScrolling} from '../utils/scroll-lock';
import {goalsSliderInit, goalsSliderRemove} from './goals-slider-init';
import {initCatalog, removeCatalog} from './init-catalog';
import {beforeAfterSliderInit, beforeAfterSliderRemove} from './beforeAfterSliderInit';
import {catalogSliderInit, catalogSliderRemove} from './catalog-slider-init';
import {initAphroditeAnimation, removeAphroditeAnimation} from './animation-aphrodite';
import {initPagingHandlers, removePagingHandlers} from './scroll-paging';
import {burgerCheck} from './burger-menu';
import {isMobile} from './is-mobile';

const initBarba = () => {
  const routes = [
    {
      path: 'main.html',
      name: 'main',
    },
    {
      path: 'values.html',
      name: 'values',
    },
    {
      path: 'aphrodite.html',
      name: 'aphrodite',
    },
    {
      path: 'alexandra.html',
      name: 'alexandra',
    },
    {
      path: 'psyche.html',
      name: 'psyche',
    },
    {
      path: 'catalog.html',
      name: 'catalog',
    },
    {
      path: 'catalog-slider.html',
      name: 'catalog-slider',
    }
  ];

  barba.use(barbaCss);

  barba.use(barbaRouter, {
    routes,
  });

  barba.init({
    views: [
      {
        namespace: 'main',
        afterEnter() {
          initPagingHandlers();
          beforeAfterSliderInit();
        },
        beforeLeave() {
          beforeAfterSliderRemove();
          removePagingHandlers();
        },
      },
      {
        namespace: 'catalog-intro',
        afterEnter() {
          initPagingHandlers();
          initCatalog();
        },
        beforeLeave() {
          removePagingHandlers();
          removeCatalog();
        },
      },
      {
        namespace: 'catalog-slider',
        afterEnter() {
          catalogSliderInit();
        },
        afterLeave() {
          catalogSliderRemove();
        },
      },
      {
        namespace: 'aphrodite',
        afterEnter() {
          initAphroditeAnimation('afrodita');
          beforeAfterSliderInit();
        },
        beforeLeave() {
          removeAphroditeAnimation();
          beforeAfterSliderRemove();
        },
      },
      {
        namespace: 'alexandra',
        afterEnter() {
          initAphroditeAnimation('aleksandra');
          beforeAfterSliderInit();
        },
        beforeLeave() {
          removeAphroditeAnimation();
          beforeAfterSliderRemove();
        },
      },
      {
        namespace: 'psyche',
        afterEnter() {
          initAphroditeAnimation('psihea');
          beforeAfterSliderInit();
        },
        beforeLeave() {
          removeAphroditeAnimation();
          beforeAfterSliderRemove();
        },
      },
      {
        namespace: 'about',
        afterEnter() {
          initPagingHandlers();
        },
        beforeLeave() {
          removePagingHandlers();
        },
      },
      {
        namespace: 'history',
        afterEnter() {
          initPagingHandlers();
        },
        beforeLeave() {
          removePagingHandlers();
        },
      },
      {
        namespace: 'goals',
        afterEnter() {
          goalsSliderInit();
          initPagingHandlers();
        },
        beforeLeave() {
          removePagingHandlers();
          goalsSliderRemove();
        },
      },
      {
        namespace: 'participants',
        afterEnter() {
          initPagingHandlers();
        },
        beforeLeave() {
          removePagingHandlers();
        },
      },
      {
        namespace: 'contacts',
        afterEnter() {
          initPagingHandlers();
        },
        beforeLeave() {
          removePagingHandlers();
        },
      }
    ],
  });

  barba.hooks.afterEnter(() => {
    enableScrolling();
    headerElWidth();
    burgerCheck();
    isMobile();
  });
};

export {initBarba};

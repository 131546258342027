import {iosVhFix} from './utils/ios-vh-fix';
import {initBarba} from './modules/barba';
import {burgerMenu} from './modules/burger-menu';
import {headerElWidth} from './modules/header-width';
import {mobileCompareInit} from './modules/mobile-compare-init';
import {initSoundModal} from './modules/toggle-sound-modal';
import {scrollBtn} from './modules/scroll-btn';

// Utils
// ---------------------------------
iosVhFix();

// Modules
// ---------------------------------
initBarba();
burgerMenu();
mobileCompareInit();
headerElWidth();
initSoundModal();
scrollBtn();

import barba from '@barba/core';

let canChange = false;
let touchStartY = false;
let touchEndY = false;

const changePage = (direction) => {
  const container = document.querySelector('[data-barba="container"]');
  const swiperSlider = container.querySelector('.swiper-container') || false;
  const isPrev = direction === 'up';
  const isNext = direction === 'down';
  let targetPage = false;
  let changeTarget = false;

  if (isPrev) {
    targetPage = container.dataset.prevPage || false;
  } else if (isNext) {
    targetPage = container.dataset.nextPage || false;
  }

  if (swiperSlider && swiperSlider.swiper) {
    const isFirstSlide = swiperSlider.swiper.isBeginning;
    const isLastSlide = swiperSlider.swiper.isEnd;

    if (canChange) {
      if (isFirstSlide) {
        changeTarget = container.dataset.prevPage;
      } else if (isLastSlide) {
        changeTarget = container.dataset.nextPage;
      }
    }

    canChange = isLastSlide || isFirstSlide;

    if (changeTarget !== targetPage) {
      return;
    }
  }

  if (targetPage) {
    barba.go(`${targetPage}.html`);
  }
};

const onScrollEvent = (evt) => {
  const isModalOpen = document.querySelector('.js-sound-modal.modal--shown');
  if (isModalOpen) {
    return;
  }
  const container = document.querySelector('[data-barba="container"]');
  const isFixedContainer = container.classList.contains('fixed');
  const isWheelEvent = evt.type === 'wheel';
  const isTouchEvent = evt.type === 'touchend';
  const isTop = window.scrollY <= 0;
  let isBottom = container.offsetHeight - (window.scrollY + window.innerHeight) <= (window.innerWidth > 1024 ? 0 : 10);
  let direction = '';

  if (isFixedContainer) {
    isBottom = window.innerHeight - (window.scrollY + window.innerHeight) <= 0;
  }

  if (isWheelEvent) {
    evt.deltaY > 0 ? direction = 'down' : direction = 'up';
  }

  if (isTouchEvent) {
    if (touchStartY - touchEndY >= 100) {
      direction = 'down';
    } else if (touchStartY - touchEndY <= -100) {
      direction = 'up';
    }
  }

  if (isTop && direction === 'up') {
    changePage(direction);
  } else if (isBottom && direction === 'down') {
    changePage(direction);
  }
};

const onTouchStart = (evt) => {
  const isModalOpen = document.querySelector('.js-sound-modal.modal--shown');
  if (isModalOpen) {
    return;
  }
  touchStartY = evt.touches[0].screenY;
  window.addEventListener('touchend', onTouchEnd);
};

const onTouchEnd = (evt) => {
  touchEndY = evt.changedTouches[0].screenY;
  onScrollEvent(evt);
  window.removeEventListener('touchend', onTouchEnd);
};

const initPagingHandlers = () => {
  if (window.matchMedia('(max-width: 767px)').matches) {
    return;
  }

  canChange = false;
  window.addEventListener('wheel', onScrollEvent);
  window.addEventListener('touchstart', onTouchStart);
};

const removePagingHandlers = () => {
  if (window.matchMedia('(max-width: 767px)').matches) {
    return;
  }

  window.removeEventListener('wheel', onScrollEvent);
};

export {initPagingHandlers, removePagingHandlers};

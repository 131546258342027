import {initSound, toggleSound} from './audio';

const initSoundModal = () => {
  const soundModalNode = document.querySelector('.js-sound-modal') || false;

  if (soundModalNode) {
    const containerNode = document.querySelector('[data-barba="container"]');
    const onModalClose = () => {
      soundModalNode.classList.remove('modal--shown');
      containerNode.classList.remove('blur');
      document.removeEventListener('click', onClick);
    };

    const onClick = (evt) => {
      const targetNode = evt.target;
      const isClose = targetNode.closest('.js-close-modal');
      const isNotSoundOn = !targetNode.closest('.js-sound-on');

      if (isClose) {
        onModalClose();
        sessionStorage.setItem('soundModalShowed', 'true');
        sessionStorage.setItem('sound', isNotSoundOn ? 'off' : 'on');
        initSound();
        toggleSound(false, isNotSoundOn);
      }
    };

    if (sessionStorage.getItem('soundModalShowed') !== 'true') {
      containerNode.classList.add('blur');
      soundModalNode.classList.add('modal--shown');
    } else {
      initSound();
      toggleSound(false, sessionStorage.getItem('sound') === 'off');
    }

    document.addEventListener('click', onClick);
  }
};

export {initSoundModal};

import ScrollMagic from '../vendor/scroll-magic';
import {audioFormat, playAudio, stopAudio} from './audio';
import {allClicks} from './switch-button-init';
import {isMobile} from './is-mobile';

/* eslint-disable */
let controller = false;
let controller2 = false;
let controller3 = false;
let intro = false;
let biography = false;
let biographySize = 3;
let history = false;
let tab1 = false;
let tab2 = false;
let tab3 = false;
let tab4 = false;
let histCards = false;

const vh100 = window.innerHeight;

const tabLineHeight = () => {
  const tabElement = document.querySelector('.biography-tab--active .biography-tab__content');

  if (tabElement) {
    const height = tabElement.getBoundingClientRect().height;
    if (height !== 0)
      document.documentElement.style.setProperty('--elHeight', Math.ceil(height) + 'px');
  }

  const lines = document.querySelectorAll('.sculpture-overlay');
  lines.forEach(line => {
    line.style.transform = 'scaleY(0)';

    setTimeout(() => {
      line.style.transform = 'scaleY(1)';
    }, 300)
  })
};

const setClassHeader = () => {
  document.querySelector('.header').classList.add('header--colored');
}

const delClassHeader = () => {
  document.querySelector('.header').classList.remove('header--colored');
}

const onRerunClick = (evt) => {
  evt.preventDefault();
  initAphroditeAnimation(document.querySelector('main').dataset.audioName);
};

const clean = (mainBlock) => {
  document.body.style.overflow = '';
  document.body.classList.remove('no-scroll');
  window.scrollTo({top: 0})
  mainBlock.scrollTo({top: 0});
}

const initAphroditeAnimation = (audioName) => {
  window.mainBlock = document.querySelector('.xxx-page') || false;
  if(!window.mainBlock) return;

  let rerun = document.querySelectorAll('.rerun-js');

  removeAphroditeAnimation(window.mainBlock);
  document.body.classList.add('sculpture-page');

  setTimeout(() => {
    let width = window.innerWidth;
    document.body.classList.remove('no-scroll');

    document.addEventListener('click', allClicks, { passive: false });

    window.addEventListener('resize', () => {
      if ((audioName) && (window.innerWidth !== width) ) {
        window.location.reload();
      }
    })

    const rootVariables = () => {
      const cardBlock = document.querySelector('.biography-tabs');
      if (!cardBlock) {
        return;
      }
      const blockOffset = cardBlock.getBoundingClientRect().y;
      const tabsBtnHeight = cardBlock.querySelector('.biography-tabs__list').getBoundingClientRect().height;
      document.documentElement.style.setProperty('--tabsBtnHeight', Math.ceil(tabsBtnHeight) + 'px');
      document.documentElement.style.setProperty('--tabsBtnHeightAndOffset', Math.ceil(tabsBtnHeight + blockOffset) + 'px');

      const historyReminder = document.querySelector('.js-reminder').getBoundingClientRect().height;
      document.documentElement.style.setProperty('--histRemind', Math.ceil(historyReminder) + 'px');
    };

    rootVariables();

    if (audioName === 'afrodita') {
      biographySize = 4;
    } else {
      biographySize = 3;
    }

    window.resultScreen = false;

    controller = new ScrollMagic.Controller();

    intro = new ScrollMagic.Scene({triggerElement: '#intro', duration: vh100 * 0.7, reverse: true});
    intro.setClassToggle('#intro', 'active');
    intro.setClassToggle('main', 'active--intro');
    intro.addTo(controller);
    intro.on('enter', (evt) => {
      delClassHeader();
      const isModalNotOpen = !document.querySelector('.js-sound-modal.modal--shown') || false;
      if (isModalNotOpen) {
        setTimeout(() => {
          const isNotResultAnimation = !document.querySelector('main.remove-animation');
          if (isNotResultAnimation) {
            playAudio(`audio/${audioName}_2.${audioFormat}`);
          }
        }, 100);
      }
    });
    intro.on('leave', () => {
      stopAudio(true);
    });

    biography = new ScrollMagic.Scene({triggerElement: '#biography', duration: vh100 * biographySize, reverse: true});
    biography.setClassToggle('#biography', 'active');
    biography.setClassToggle('main', 'active--biography');
    biography.on('enter', () => {
      setClassHeader();
      setActiveNavHeader('#biography-nav');
      rootVariables();
      setTimeout(() => {
        const isNotResultAnimation = !document.querySelector('main.remove-animation');
        if (isNotResultAnimation) {
          playAudio(`audio/${audioName}_3.${audioFormat}`);
        }
      }, 510);
    });
    biography.on('leave', () => {
      let activeBio = document.querySelector('.biography-tab--active');
      if(activeBio)
        activeBio.classList.remove('biography-tab--active')
    })

    biography.addTo(controller);

    controller2 = new ScrollMagic.Controller({container: '.biography-tabs__list'});

    tab1 = new ScrollMagic.Scene({triggerElement: '#tab1-trigger', duration: vh100 });
    tab1.addTo(controller2);
    tab1.setClassToggle('#tab1', 'biography-tab--active');
    tab1.on('enter', () => {
      tabLineHeight();
      document.body.style.overflow = '';
    });

    tab2 = new ScrollMagic.Scene({triggerElement: '#tab2-trigger', duration: vh100 });
    tab2.addTo(controller2);
    tab2.setClassToggle('#tab2', 'biography-tab--active');
    tab2.on('enter', () => {
      tabLineHeight();
    });

    tab3 = new ScrollMagic.Scene({triggerElement: '#tab3-trigger', duration: vh100 });
    tab3.addTo(controller2);
    tab3.setClassToggle('#tab3', 'biography-tab--active');
    tab3.on('enter', tabLineHeight);

    if (audioName === 'afrodita') {
      tab4 = new ScrollMagic.Scene({triggerElement: '#tab4-trigger', duration: vh100 });
      tab4.addTo(controller2);
      tab4.setClassToggle('#tab4', 'biography-tab--active');
      tab4.on('enter', tabLineHeight);
    }


    if (isMobile()) {
      history = new ScrollMagic.Scene({triggerElement: '#history', duration: vh100 * 2 , reverse: true});
      history.addTo(controller);
      history.setClassToggle('#history', 'active');
      history.setClassToggle('main', 'active--history');
      history.on('enter', () => {
        setActiveNavHeader('#history-nav');
      });

      controller3 = new ScrollMagic.Controller({container: '.cards-history'});
      histCards = new ScrollMagic.Scene({triggerElement: '#history-cards-trigger', duration: vh100 * 2 });
      histCards
        .setClassToggle('main', 'cards-history--active')
        .addTo(controller3)

    } else {

      history = new ScrollMagic.Scene({triggerElement: '#history', duration: vh100, reverse: true});
      history.setClassToggle('#history', 'active');
      history.setClassToggle('main', 'active--history');
      history.on('enter', () => {
        setActiveNavHeader('#history-nav');
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
          window.mainBlock.classList.add('history-step-1');
        }, 600);
      });
      history.on('leave', () => {
        document.body.style.overflow = '';
        setTimeout(() => {
          window.mainBlock.classList.remove('history-step-1');
        }, 600);
      });
      history.addTo(controller);
    }


    const setActiveNavHeader = (id) => {
      let allItems = document.querySelectorAll('.inner-nav__link');
      allItems.forEach(item => item.classList.remove('active'));

      let activeItem = document.querySelector(id);
      if (activeItem) activeItem.classList.add('active')
    }

    rerun.forEach(btn => btn.addEventListener('click', onRerunClick));
  }, 60);
};


const removeAphroditeAnimation = (mainBlock) => {

  document.body.classList.remove('sculpture-page');
  document.removeEventListener('click', allClicks);
  let rerun = document.querySelectorAll('.rerun-js');

  if (controller) {
    controller.destroy(true);
    controller = false;
    intro.destroy(true);
    intro = false;
    biography.destroy(true);
    biography = false;
    history.destroy(true);
    history = false;
  }

  if (controller2) {
    controller2.destroy(true);
    controller2 = false;
    tab1.destroy(true);
    tab1 = false;
    tab2.destroy(true);
    tab2 = false;
    tab3.destroy(true);
    tab3 = false;

    if(tab4) {
      tab4.destroy(true);
      tab4 = false;
    }
  }

  if (controller3) {
    controller3.destroy(true);
    controller3 = false;
    histCards.destroy(true);
    histCards = false;
  }


  const isModalNotOpen = !document.querySelector('.js-sound-modal.modal--shown') || false;
  if (isModalNotOpen) {
    stopAudio();
  }

  if (!mainBlock) {
    return;
  }

  mainBlock.classList.remove('remove-animation');
  mainBlock.classList.remove('finish-animation');

  const mainClassListArray = Array.from(mainBlock.classList);
  const removeClassesArray = mainClassListArray.filter((item) => item.includes('history') || item.includes('active'));
  removeClassesArray.length ? mainBlock.classList.remove(...removeClassesArray) : '';

  const cards = document.querySelectorAll('.card--active');
  cards.forEach((el) => {
    el.classList.remove('card--remove-animation');
    el.classList.remove('card--active');
    el.style.display = '';
    el.style.visibility = '';

    el.querySelector('input').checked = false;
  });

  window.cardNumber = 0;
  window.current = 0;

  let showAllBtn = document.querySelector('.js-show-all');
  if (showAllBtn)
    showAllBtn.classList.add('button--disabled');

  window.resultScreen = false;

  rerun.forEach(btn => btn.removeEventListener('click', onRerunClick));

  setTimeout(() => {
    clean(mainBlock);
  }, 10);

  setTimeout(() => {
    document.body.classList.remove('replay');
    clean(mainBlock);
  }, 50);
};

export {initAphroditeAnimation, removeAphroditeAnimation};
